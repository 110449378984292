import styled, { css } from 'styled-components';
import { titleSans, yellow, soberBlue } from 'styles/variables';
import { paragraphMixin } from 'styles/typography';
import media from 'styles/media';

const limit = 10;

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  margin: 2rem 0;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  min-height: ${props => props.size * 1.1}rem;
  position: relative;

  .description {
    position: relative;
    ${paragraphMixin};
    line-height: 1.4rem;
    text-align: center;
    margin: 0.5rem 0 0;
    color: ${props => (props.size > limit ? '#fff' : '#000')};
    width: ${props => (props.size > limit ? '100%' : '50%')};

    ${media.sm`
      margin: 0;
      width: ${props => (props.size > limit ? '100%' : '10rem')};
    `};
  }
`;

export const Bubble = styled.div`
  ${props => css`
    width: ${props.size}rem;
    height: ${props.size}rem;
  `};
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  .figure {
    ${({ size }) => css`
      font-size: ${size / 5.3333}rem;
      line-height: ${size / 5.333}rem;
      color: ${size < limit ? soberBlue : yellow};
    `};
    position: relative;
    font-family: ${titleSans};
    font-weight: 600;
    margin: 0;
    padding: 0;
    letter-spacing: -2px;
    text-align: center;
    z-index: 1;

    .emphasis {
      color: ${yellow};
      display: block;
      position: relative;
      ${({ size }) => css`
        font-size: ${size / 2.56}rem;
        line-height: ${size / 2.56}rem;
        margin: ${size > limit ? 0 : '1rem'};
      `};

      &:after {
        content: '%';
      }

      &:before {
        ${({ size }) =>
          size < 10
            ? css`
                content: '';
              `
            : css`
                content: none;
              `};
        position: absolute;
        background-color: ${soberBlue};
        height: 80px;
        width: 80px;
        top: -12px;
        left: -1px;
        border-radius: 100%;
        z-index: -1;
      }
    }
  }

  .bubble {
    border-radius: 100%;
    position: absolute;
    background-color: ${soberBlue};
    width: 125%;
    height: 125%;
    z-index: 0;
  }

  /* IE11 hacks*/
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .bubble {
      left: -15%;
      top: -15%
    }
  }
`;

import React from 'react';
import Iceberg from 'modules/Iceberg';
import { Wrapper } from './styled';

function IcebergBlock() {
  return (
    <Wrapper>
      <Iceberg
        slides={[
          {
            message: 'future_visIceberg1',
            image: 'future/iceberg-technology',
            caption: 'future_imageIndustrobotsCaption'
          },
          {
            message: 'future_visIceberg2',
            image: 'future/iceberg-climate',
            caption: 'future_imageAerialCaption'
          },
          {
            message: 'future_visIceberg3',
            image: 'future/iceberg-children',
            caption: 'future_imageChildrenCaption'
          }
        ]}
      />
    </Wrapper>
  );
}

export default IcebergBlock;

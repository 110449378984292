import styled from 'styled-components';
import media from 'styles/media';

export const Wrapper = styled.div`
  margin: 2rem 0;

  ${media.sm`
    height: auto;
    width: 100%;
    margin: 5rem 0 0;
  `};
`;

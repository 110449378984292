import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Motion, spring } from 'react-motion';
import { Wrapper, Bubble } from './styled';

function BubbleFacts({ ready = false, size = 8, message = {} }) {
  const count = ready ? message.figure : 0;

  return (
    <Wrapper size={size}>
      <Bubble size={size}>
        {size > 10 && <div className="bubble" />}
        <div className="figure">
          <Motion
            defaultStyle={{ x: 0 }}
            style={{
              x: spring(count, {
                precision: 1,
                stiffness: 80,
                damping: 30
              })
            }}
          >
            {value => <span className="emphasis">{Math.floor(value.x)}</span>}
          </Motion>
          <FormattedMessage id={message.unit} />
        </div>
        {size > 10 && (
          <div className="description">
            <FormattedMessage id={message.description} />
          </div>
        )}
      </Bubble>
      {size < 10 && (
        <div className="description">
          <FormattedMessage id={message.description} />
        </div>
      )}
    </Wrapper>
  );
}

BubbleFacts.propTypes = {
  size: PropTypes.number.isRequired,
  message: PropTypes.shape({
    figure: PropTypes.string.isRequired,
    unit: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired,
  ready: PropTypes.bool.isRequired
};

export default BubbleFacts;

import React from 'react';
import { theme } from 'config/future.json';
import { withIntl } from 'i18n';
import Template from 'modules/ChapterTemplate';
import Iceberg from 'blocks/future/BlockIceberg';
import InfoBoxBlock from 'modules/InfoBox';
import CalloutSlider from 'modules/CalloutSlider';
import PhotoGrid from 'blocks/future/Block-PhotoGrid';
import CalloutHeroCollage from 'blocks/future/Block-CalloutHeroCollage';
import GenderGapBlock from 'blocks/future/Block-GenderGap';
import InfoCollage from 'modules/InfoCollage';
import Quote from 'modules/Quote';
import InfoParallax from 'modules/InfoParallax';
import Videos from 'modules/Videos';
import CalloutStampBlock from 'modules/CalloutStamp';
import Conclusion from 'modules/Conclusion';
import Noise from 'components/Sound/Noise';

const Future = () => (
  <Template theme={theme}>
    <InfoBoxBlock
      id="future_infoLookingtowards"
      col={{
        xs: 12,
        sm: 7,
        smOffset: 2,
        md: 6,
        mdOffset: 3
      }}
    />
    <Iceberg />
    <CalloutSlider />
    <PhotoGrid />
    <Noise id="future/computer-users" volume={70}>
      <CalloutHeroCollage />
    </Noise>
    <GenderGapBlock />
    <Quote
      imageID="future/kofi-annan"
      quoteID="future_quoteKofiBody"
      captionID="future_quoteKofiCaption"
      imageWidth="250px"
    />
    <Noise id="future/farming" volume={70}>
      <InfoCollage
        collage={{
          colProps: {
            xs: 12,
            sm: 12,
            smOffset: 0,
            md: 9,
            mdOffset: 3,
            lgOffset: 4,
            lg: 10
          },
          layers: [
            'future/coop-costarica-1',
            'future/coop-costarica-2',
            'future/coop-costarica-3'
          ],
          caption: {
            message: 'future_imageEveldoCaption',
            colProps: {
              xs: 12,
              sm: 10,
              smOffset: 2,
              mdOffset: 4,
              md: 6
            }
          }
        }}
        infoBoxes={[
          {
            infoBox: {
              scroll: true,
              size: {
                lg: '33rem',
                md: '25rem'
              }
            },
            colProps: {
              xs: 12,
              sm: 8,
              smOffset: 2,
              md: 5,
              mdOffset: 0,
              lg: 6
            },
            message: 'future_infoNewforms',
            justify: 'flex-end',
            position: {
              sm: 'relative'
            }
          }
        ]}
        voffset={{
          sm: '0',
          lg: '0 0 15rem',
          md: '0 0 10rem'
        }}
      />
    </Noise>
    <InfoParallax
      figure={{
        colProps: {
          xs: 12,
          sm: 12,
          md: 8,
          mdOffset: 0
        },
        image: 'future/women-coops',
        caption: 'future_imageWomencoopCaption',
        captionAlign: 'ltr',
        captionPadding: {
          lg: '0 50% 0 0',
          md: '0 35% 0 0'
        }
      }}
      infoBox={{
        infoBox: {
          scroll: true,
          size: {
            lg: '30rem',
            md: '20rem'
          }
        },
        caption: {},
        colProps: {
          xs: 12,
          smOffset: 2,
          sm: 7,
          md: 5,
          mdOffset: 6,
          lg: 6,
          lgOffset: 5
        },
        message: 'future_infoThesebusinesses',
        justify: {
          sm: 'flex-start',
          md: 'flex-end',
          lg: 'flex-end'
        }
      }}
      voffset={{
        lg: '0 0 10rem',
        md: '0',
        sm: '18rem 0 0',
        xs: '0'
      }}
    />
    <Noise id="enterprises/market" volume={70}>
      <InfoParallax
        figure={{
          colProps: {
            xs: 12,
            sm: 12,
            mdOffset: 4,
            md: 8
          },
          image: 'future/flower-vendor',
          caption: 'future_imageFlowersellerCaption',
          captionAlign: 'ltr',
          captionPadding: {
            lg: '0 0 0 40%',
            md: '0 0 0 35%'
          }
        }}
        infoBox={{
          infoBox: {
            scroll: true,
            size: {
              lg: '30rem',
              md: '20rem'
            }
          },
          caption: {},
          colProps: {
            xs: 12,
            smOffset: 0,
            sm: 7,
            md: 5,
            mdOffset: 0,
            lg: 6,
            lgOffset: 0
          },
          message: 'future_infoModernizing',
          justify: {
            sm: 'flex-start',
            md: 'flex-end',
            lg: 'flex-end'
          }
        }}
        voffset={{
          lg: '0 0 10rem',
          md: '0',
          sm: '18rem 0 0',
          xs: '0'
        }}
      />
    </Noise>
    <InfoParallax
      figure={{
        colProps: {
          xs: 12,
          sm: 12,
          md: 12,
          mdOffset: 0
        },
        image: 'future/global-commission',
        caption: 'future_imageOfficialCaption',
        captionPadding: {
          lg: '0 65% 0 0',
          md: '0 60% 0 0',
          sm: '0 65% 0 0'
        }
      }}
      infoBox={[
        {
          infoBox: {
            scroll: true,
            size: {
              lg: '30rem',
              md: '25rem',
              sm: '20rem'
            }
          },
          colProps: {
            xs: 12,
            sm: 7,
            smOffset: 0,
            md: 6,
            mdOffset: 5,
            lgOffset: 6,
            lg: 6
          },
          message: 'future_infoHumancentred',
          justify: {
            sm: 'flex-start',
            md: 'flex-start',
            lg: 'flex-start'
          }
        },
        {
          infoBox: {
            scroll: true,
            size: {
              lg: '30rem',
              md: '25rem',
              sm: '20rem'
            }
          },
          colProps: {
            xs: 12,
            sm: 7,
            smOffset: 4,
            md: 5,
            mdOffset: 5,
            lg: 6
          },
          message: 'future_infoFuturewewant',
          justify: {
            sm: 'flex-end',
            md: 'flex-end',
            lg: 'flex-end'
          }
        }
      ]}
      voffset={{
        lg: '20rem 0 15rem',
        md: '20rem 0 8rem',
        sm: '25rem 0 8rem',
        xs: '0'
      }}
    />
    <Videos
      videos={[
        'future/videoUlf',
        'future/videoVishani',
        'future/videoNicolas',
        'future/videoBernice'
      ]}
    />
    <CalloutStampBlock
      stamp="pinkcircle"
      calloutId="future_calloutParadox"
      subtextId="future_calloutSickelderly"
    />
    <InfoCollage
      collage={{
        colProps: {
          xs: 12,
          sm: 12,
          smOffset: 0,
          md: 9,
          mdOffset: 3,
          lgOffset: 4,
          lg: 10
        },
        layers: [
          'future/syrian-worker-1',
          'future/syrian-worker-2',
          'future/syrian-worker-3'
        ],
        caption: {
          message: 'future_imageSyrianCaption',
          colProps: {
            xs: 12,
            sm: 10,
            smOffset: 2,
            mdOffset: 4,
            md: 6
          }
        }
      }}
      infoBoxes={[
        {
          infoBox: {
            scroll: true,
            size: {
              lg: '33rem',
              md: '25rem'
            }
          },
          colProps: {
            xs: 12,
            sm: 8,
            smOffset: 2,
            md: 5,
            mdOffset: 0,
            lg: 6
          },
          message: 'future_infoFulfilling',
          justify: 'flex-end',
          position: {
            sm: 'relative'
          }
        }
      ]}
      voffset={{
        sm: '0',
        lg: '0 0 15rem',
        md: '0 0 10rem'
      }}
    />
    <Videos videos="future/videoRyder" />
    <InfoCollage
      collage={{
        colProps: {
          xs: 12,
          md: 12,
          lg: 12
        },
        layers: [
          'future/workers-generic-1',
          'future/workers-generic-2',
          'future/workers-generic-3'
        ]
      }}
      infoBoxes={[
        {
          colProps: {
            xs: 12,
            sm: 8,
            smOffset: 2,
            md: 6,
            mdOffset: 3,
            lgOffset: 3,
            lg: 6
          },
          message: 'future_infoNext100Years',
          justify: 'flex-start',
          position: {
            sm: 'relative'
          }
        }
      ]}
      voffset={{
        sm: '5rem 0 0',
        md: '30rem 0 0',
        lg: '44rem 0 0'
      }}
    />
    <Conclusion
      mainTextId="future_calloutHistory"
      subTextId="future_calloutInthisspirit"
    />
  </Template>
);

export default withIntl(Future);

import React, { useState } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { Outer, Inner } from 'containers/Wrapper';
import { Row, Col } from 'react-styled-flexboxgrid';
import Controller from 'modules/Iceberg/Controller';
import Fade from 'components/Fade';
import PropTypes from 'prop-types';
import { Wrapper, ContentWrapper, Message } from './styled';

function CalloutSlider({
  slides = [
    'future_visQuestions1',
    'future_visQuestions2',
    'future_visQuestions3',
    'future_visQuestions4'
  ]
}) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slideMounted, setSlideMounted] = useState(true);

  const handleOnExiting = () => {
    setSlideMounted(true);
  };

  const nextSlide = () => {
    if (currentSlide < slides.length - 1) {
      setSlideMounted(false);
      setTimeout(() => {
        setCurrentSlide(currentSlide + 1);
      }, 250);
    }
  };

  const prevSlide = () => {
    if (currentSlide > 0) {
      setSlideMounted(false);
      setTimeout(() => {
        setCurrentSlide(currentSlide - 1);
      }, 250);
    }
  };

  return (
    <Wrapper>
      <Outer>
        <Inner>
          <Row center="xs">
            <Col xs={12} sm={10} smOffset={0} md={8}>
              <ContentWrapper>
                <Fade inProp={slideMounted} onExiting={handleOnExiting}>
                  <Message>
                    <FormattedHTMLMessage id={slides[currentSlide]} />
                  </Message>
                </Fade>
              </ContentWrapper>
            </Col>
          </Row>
          <Row center="xs">
            <Col xs={11} sm={6}>
              <Controller
                dotsColor="soberBlue"
                slides={slides}
                nextSlide={nextSlide}
                prevSlide={prevSlide}
                currentSlide={currentSlide}
              />
            </Col>
          </Row>
        </Inner>
      </Outer>
    </Wrapper>
  );
}

CalloutSlider.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default CalloutSlider;

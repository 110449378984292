import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { Outer, Inner } from 'containers/Wrapper';
import { Row, Col } from 'react-styled-flexboxgrid';
import InfoBox from 'components/InfoBox';
import { FormattedHTMLMessage } from 'react-intl';
import Bubble from 'components/BubbleFacts';
import VisibilitySensor from 'react-visibility-sensor';
import Figure from 'components/Figure';
import withResponsive from 'containers/Responsive/withResponsive';
import { Wrapper } from './styled';

function Block() {
  const [visible, setVisible] = useState(false);

  const handleVisibilityChange = isVisible => {
    if (!visible) {
      setVisible(isVisible);
    }
  };

  return (
    <Outer>
      <Inner>
        <Row>
          <Col xs={12} sm={8} smOffset={2} mdOffset={0} md={6}>
            <InfoBox
              scroll
              size={{
                lg: '33rem',
                md: '33rem',
                sm: '25rem'
              }}
            >
              <FormattedHTMLMessage id="future_infoOpportunity" />
            </InfoBox>
          </Col>
          <VisibilitySensor
            onChange={handleVisibilityChange}
            active={!visible}
            partialVisibility
            offset={{ bottom: 300 }}
          >
            <Col
              xs={12}
              mdOffset={2}
              md={4}
              style={{
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center'
              }}
            >
              <Wrapper>
                <Bubble
                  ready={visible}
                  size={13}
                  message={{
                    figure: 42,
                    unit: 'future_visOfWomen',
                    description: 'future_visWomen'
                  }}
                />
                <Bubble
                  ready={visible}
                  size={8}
                  message={{
                    figure: 6,
                    unit: 'future_visOfMen',
                    description: 'future_visMen'
                  }}
                />
              </Wrapper>
            </Col>
          </VisibilitySensor>
        </Row>
        <Row>
          <Col xs={12} smOffset={1} sm={10}>
            <Figure
              image="future/domestic-worker"
              caption="future_imageDomesticCaption"
            />
          </Col>
        </Row>
      </Inner>
    </Outer>
  );
}

export default withResponsive(Block);

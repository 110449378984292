import React from 'react';
import CalloutHero from 'modules/CalloutHero';
import { injectIntl } from 'react-intl';
import { Outer, Inner } from 'containers/Wrapper';
import { Row, Col } from 'react-styled-flexboxgrid';
import Collage from 'components/Collage';
import {
  Wrapper,
  BottomLayer,
  CollageWrapper,
  CollageInnerWrapper
} from './styled';

const collage = {
  layers: [
    'future/school-children-1',
    'future/school-children-2',
    'future/school-children-3'
  ],
  caption: {
    message: 'future_imageSchoolgirlsCaption',
    colProps: {
      xs: 10,
      xsOffset: 1,
      sm: 6,
      smOffset: 2
    }
  }
};

function Block({ intl: { locale } }) {
  return (
    <Wrapper>
      <CalloutHero
        imageId="future/brighter-future"
        calloutId="future_calloutBrighterfuture"
        bottomTear={false}
        infoboxId="future_infoSkillsweneed"
        captionPadding={{
          lg: '0 55% 0 0',
          md: '0 55% 0 0'
        }}
        outerHeight={{
          lg: '80rem',
          md: '75rem',
          sm: '70rem'
        }}
        infoBoxProps={{
          scroll: true,
          size: {
            lg: '33rem',
            md: '28rem',
            sm: '25rem'
          }
        }}
        infoBoxColProps={{
          xs: 12,
          sm: 8,
          smOffset: 2,
          md: 5,
          mdOffset: 6,
          lg: 6
        }}
        figcaption="future_imageChildrencodeCaption"
      />
      <Outer>
        <Inner>
          <Row>
            <Col xs={12} sm={12} mdOffset={-1} md={9} lg={10} lgOffset={-2}>
              <BottomLayer>
                <CollageWrapper>
                  <CollageInnerWrapper>
                    <Collage
                      imageStyle={{
                        height: { lg: 'auto' },
                        width: { lg: '100% ' }
                      }}
                      layerStyle={{
                        height: 'auto',
                        width: '100%'
                      }}
                      layers={collage.layers}
                      caption={collage.caption}
                    />
                  </CollageInnerWrapper>
                </CollageWrapper>
              </BottomLayer>
            </Col>
          </Row>
        </Inner>
      </Outer>
    </Wrapper>
  );
}

Block.propTypes = {

};

export default injectIntl(Block);

import styled from 'styled-components';
import media from 'styles/media';

export const Wrapper = styled.div`
  height: auto;
  width: 100%;
`;

export const BottomLayer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  min-height: 33rem;

  ${media.sm`
    min-height: 25rem;
  `};

  ${media.xs`
    min-height: 17rem;
  `};
`;

export const CollageWrapper = styled.div`
  position: absolute;
  top: -10rem;
  width: 100%;

  ${media.sm`
    position: relative;
    top: 0;
  `};
`;

export const CollageInnerWrapper = styled.div`
  position: relative;
  width: 100%;
`;

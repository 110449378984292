import React from 'react';
import PhotoGrid from 'modules/PhotoGrid';
import { injectIntl } from 'react-intl';
import getContent from 'utils/getContentBySubstring';
import CalloutStampBlock from 'modules/CalloutStamp';
import Wrapper from './styled';

const Block = ({ intl: { messages } }) => (
  <Wrapper>
    <CalloutStampBlock
      stamp="fingerprint"
      calloutId="future_calloutIloidentified"
      subtextId="future_calloutExplorethem"
    />
    <PhotoGrid
      items={[
        {
          image: 'future/detail-greenjobs',
          name: 'future_visGreenjobsHeading',
          detail: {
            messages: getContent(messages, 'future_visGreenjobsDetail'),
            figureProps: {
              image: 'future/detail-greenjobs',
              caption: 'future_imageMarineCaption'
            }
          }
        },
        {
          image: 'future/detail-care',
          name: 'future_visCareeconomyHeading',
          detail: {
            messages: getContent(messages, 'future_visCareeconomyDetail'),
            figureProps: {
              image: 'future/detail-care',
              caption: 'future_imageNurseCaption'
            }
          }
        },
        {
          image: 'future/detail-platformeconomy',
          name: 'future_visGigeconomyHeading',
          detail: {
            messages: getContent(messages, 'future_visGigeconomyDetail'),
            figureProps: {
              image: 'future/detail-platformeconomy',
              caption: 'future_imageManworkshomeCaption'
            }
          }
        },
        {
          image: 'future/detail-ruraleconomy',
          name: 'future_visRuraleconomyHeading',
          detail: {
            messages: getContent(messages, 'future_visRuraleconomyDetail'),
            figureProps: {
              image: 'future/detail-ruraleconomy',
              caption: 'future_imageTwofarmersCaption'
            }
          }
        },
        {
          image: 'future/detail-globalsupply',
          name: 'future_visGlobalsupplyHeading',
          detail: {
            messages: getContent(messages, 'future_visGlobalsupplyDetail'),
            figureProps: {
              image: 'future/detail-globalsupply',
              caption: 'future_imageGarmentCaption'
            }
          }
        },
        {
          image: 'future/detail-robotage',
          name: 'future_visRobotageHeading',
          detail: {
            messages: getContent(messages, 'future_visRobotageDetail'),
            figureProps: {
              image: 'future/detail-robotage',
              caption: 'future_imageRobotsiranCaption'
            }
          }
        }
      ]}
    />
  </Wrapper>
);

export default injectIntl(Block);

import styled from 'styled-components';
import { rose } from 'styles/variables';
import { h1Mixin } from 'styles/typography';
import media from 'styles/media';

export const Wrapper = styled.div`
  margin: 5rem 0 0;
  width: 100%;
  position: relative;
`;

export const ContentWrapper = styled.div`
  min-height: 400px;
  height: 100%;

  ${media.sm`
    min-height: 450px;
  `};
`;

export const Message = styled.h3`
  ${h1Mixin};
  font-size: 70px;
  line-height: 85px;
  letter-spacing: -2px;
  color: #202c45;
  margin: 0;

  ${media.sm`
    font-size: 60px;
    line-height: 72px;
  `};

  ${media.xs`
    font-size: 48px;
    line-height: 55px;
  `};

  span.emphasis {
    color: ${rose};
  }
`;
